<template>
  <v-sheet elevation="2" class="pa-5" overflow="scroll">
    <v-form v-model="valid" ref="form" @submit.prevent="saveCustomer">
      <v-container>
        <!--Header-->
        <v-row class="mb-5">
          <v-col md="12" sm="12">
            <h2>Add New Customer</h2>
          </v-col>
        </v-row>
        <!--Personal details-->
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <h4>Personal Information</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="customer_name"
              :rules="[
                RequireFormInput('name'),
                minLength('name', 2),
                maxLength('name', 50),
              ]"
              counter="50"
              label="Full Name"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="customer_status"
              :items="['Available', 'Unavailable']"
              label="Availability status"
              required
              outlined
              dense
              color="#3d2cdd"
            >
            </v-select>
          </v-col>
        </v-row>
        <!--Contact details-->
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <h4>Contact Details</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="customer_cell"
              label="Cell Number"
              append-icon="mdi-phone"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="customer_email"
              label="Email"
              append-icon="mdi-email"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!--Address-->
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <h4>Delivery Address</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="customer_addr_1"
              label="Address Line 1"
              append-icon="mdi-map-marker"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="customer_addr_2"
              label="Address Line 2"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="customer_city"
              label="City"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="customer_zip"
              label="Zip Code"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!--New-->
              <span>
                <v-btn text left class="px-3" @click="passDialogChange($event)"
                  >Cancel</v-btn
                >
                <v-btn
                  right
                  color="#33cc99"
                  class="white--text px-3"
                  :disabled="!valid"
                  :loading="loading === true"
                  @click="
                    saveCustomer();
                    passCustomerDetails();
                    passDialogChange($event);
                  "
                  >Submit
                  <v-icon right>mdi-check</v-icon>
                </v-btn>
              </span>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-sheet>
</template>
<script>
//Connect to database
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { saveCustomer } from "../../Customers/data/external_customers";
import mixin_formValidation from "../../Customers/mixins/mixin_formValidation";
export default {
  name: "NewCustomerModal",
  mixins: [mixin_formValidation],
  data() {
    return {
      customer_id: null,
      customer_name: null,
      customer_cell: null,
      customer_email: null,
      customer_addr_1: null,
      customer_addr_2: null,
      customer_city: null,
      customer_zip: null,
      customer_status: "Available",
      customer_date: null,
      customer_timestamp: null,
      customer_type: "customer_individual",
      loading: false,
      customer_created_by_id: null,
      customer_created_by_name: null,
    };
  },
  created() {
    //Captures user that created the sales order
    this.customer_created_by_id = firebase.auth().currentUser.uid;
    db.collection("users")
      .where("uid", "==", this.customer_created_by_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.customer_created_by_name = doc.data().displayName;
        });
      });
  },
  methods: {
    saveCustomer,
    passCustomerDetails() {
      this.$emit("passCustomerDetails", this.customer_name);
    },
    passDialogChange() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>